import React from "react"
import Page from "../components/page";
import RatingsGrid from "../components/ratingsgrid";
import { Link } from "gatsby";
import Seasons from "../../data/seasons.json";

const SEASON_CODE='2425';

const CurrentSeasonPage = (props) => {
  const season=Seasons.find(s=>s['code']===SEASON_CODE);
  const seasonName = season['name'];
  const pageTitle="Current Season College Basketball Ratings";
  return (
    <Page location={props.location.href} pageTitle={pageTitle}>
    <h1>{pageTitle}</h1>
      <p>Here are the most up-to-date ratings of the {seasonName} NCAA Men's College Basketball season. To learn more about the methodology click <Link to="/methodology">here</Link>
      </p>
      <RatingsGrid season={SEASON_CODE} />
    </Page>
    );
};
export default CurrentSeasonPage;