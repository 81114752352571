export function formatPct(x,digits=0) {
    let pct=(parseFloat(x)*100).toFixed(digits)
    pct = (pct<1.0 && pct>-1.0) ? 0.0 : pct
    return pct+"%";
}

export function formatRating(x) {
    return parseFloat(x).toFixed(4)
}
export function formatRatingShort(x) {
    return parseFloat(x).toFixed(2)
}
export function formatPointSpread(x) {
    let s = parseFloat(x);
    //round to nearest .5
    s = Math.round(s * 2) / 2;
    return (Math.sign(s) === 1 ? '+' : '') + s.toFixed(2)
}