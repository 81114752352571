import React from "react"
import Page from "../components/page";
import { Link } from "gatsby";
import TodaysMatchups from "../../data/today.json";
import { TeamRating, Team, TeamRecord, HomeEdge } from "../dataaccess";
import { DataGrid } from '@mui/x-data-grid';
import { formatRatingShort,formatPointSpread } from "../utils";

const SEASON_CODE='2425';

const TodaysMatchupsPage = (props) => {
  //const season=Seasons.find(s=>s['code']===SEASON_CODE);
  //const seasonName = season['name'];
  const pageTitle="Today's College Basketball Matchups";
  return (
    <Page location={props.location.href} pageTitle={pageTitle}>
    <h1>{pageTitle}</h1>
      <p>Here are the matchups for <b>{TodaysMatchups['game_date']}</b> in NCAA Men's College Basketball, along with the predicted margin of victory. To learn more about the methodology click <Link to="/methodology">here</Link>
      </p>
      {TodaysGamesTable()}
    </Page>
    );
};

const cellClassName =(params)=>{
  const d = spreadDelta(params.row.home_odds, params.row.predicted_margin);
  if (d >= 8.0) return 'spreadDeltaHigh';
  if (d >= 5.0) return 'spreadDeltaMid';
  if (d >= 3.0) return 'spreadDeltaSmall'
  return 'plainText'
  };

const TodaysGamesTable = () => {
      // define the columns of the grid and their display properties
    const columns = [
        { field: 'away_team_name', headerName: 'School', width: 140, },
        { field: 'away_wins', headerName: 'W', width: 24 },
        { field: 'away_losses', headerName: 'L', width: 24 },
        { field: 'away_rating', headerName: 'Rating', width: 80 },
        { field: 'home_team_name', headerName: 'School', width: 140, },
        { field: 'home_wins', headerName: 'W', width: 24 },
        { field: 'home_losses', headerName: 'L', width: 24 },
        { field: 'home_rating', headerName: 'Rating', width: 80 },
        { field: 'home_odds', headerName: 'Current', width: 80, cellClassName: cellClassName },
        { field: 'predicted_margin', headerName: 'Predicted', width: 80, cellClassName: cellClassName
        }
    ];

    const columnGroupingModel=[
        {
            groupId: 'away_team',headerName:'Away',
            children: [{field: 'away_team_name'},{field: 'away_wins'},{field: 'away_losses'},{field: 'away_rating'}],
        },{
            groupId: 'home_team',headerName:'Home',
            children: [{field: 'home_team_name'},{field: 'home_wins'},{field: 'home_losses'},{field: 'home_rating'}],
        },
        {
          groupId: 'odds',headerName:'Point Spread (home)',
          children: [{field: 'home_odds'},{field: 'predicted_margin'}],
        },
      ]

      const home_edge = HomeEdge(SEASON_CODE);

      const rows = TodaysMatchups['games'].map(g => {
        const home_team = Team(g['home_team']['team_code']);
        const away_team = Team(g['away_team']['team_code']);
        const home_record = TeamRecord(home_team['team_code'], SEASON_CODE);
        const away_record = TeamRecord(away_team['team_code'], SEASON_CODE);
        const home_rating = TeamRating(g['home_team']['team_code'], SEASON_CODE);
        const away_rating = TeamRating(g['away_team']['team_code'], SEASON_CODE);
        const predicted_home_margin = home_rating - away_rating + home_edge;
        const predicted_home_odds = -1 * predicted_home_margin;
        const row = {
          "away_team_name": (away_team !== null) ? away_team['school'] : g['away_team']['team_code'],
          "away_wins"     : away_record['overall']['W'],
          "away_losses"   : away_record['overall']['L'],
          "away_rating"   : formatRatingShort(away_rating),

          "home_team_name": (home_team !== null) ? home_team['school'] : g['home_team']['team_code'],
          "home_wins"     : home_record['overall']['W'],
          "home_losses"   : home_record['overall']['L'],
          "home_rating"   : formatRatingShort(home_rating),
          

          "home_odds"  : g['home_odds'],
          "predicted_margin": formatPointSpread(predicted_home_odds),
          "game_id": g['game_id']
        };
        return row;
      })//.filter(t => (selectedConference === "" || selectedConference === "All Conferences" || t['conference'] === selectedConference));

    return (
        <DataGrid experimentalFeatures={{ columnGrouping: true }}
        rows={rows}
        columns={columns}
        columnGroupingModel={columnGroupingModel}
        density="compact"
        getCellClassName={() => "rating"}
        initialState={{
          pagination: {
            paginationModel: {
            pageSize: 100,
            },
          }, 
          sorting: {
            sortModel: [{ field: 'home_team_name', sort: 'asc' }],
          }
        }}
        disableRowSelectionOnClick
        disableColumnMenu={true}
        getRowId={(row) =>  row.game_id}
        />);

}
const spreadDelta=(x,y)=>{
  const a=(x==='PK')?0:parseFloat(x);
  const b=(y==='PK')?0:parseFloat(y);
  return Math.abs((b-a));
}
export default TodaysMatchupsPage;