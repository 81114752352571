import React from "react"
import Page from "../components/page";
import RatingsGrid from "../components/ratingsgrid";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Seasons from "../../data/seasons.json";

export default class HistoricalPage extends React.Component {
    state = {
        season_code: "2324"
    }
    componentDidMount() {
      this.selectSeason("2324");
    }
    isSeasonSelected = (season_code) => this.state.season_code === season_code;
    
    yearButton = (season_code, description) => <Button variant="text" size="large" disabled={this.isSeasonSelected(season_code)} onClick={(e)=>this.selectSeason(season_code)}>{description}</Button>;
    selectedSeasonName(){
      let season_code = this.state.season_code;
      let season=Seasons.find(s=>s['code']===season_code);
      return season['name'];
    }
    


    selectSeason = (season_code) => this.setState({season_code:season_code});
    
    render() {
      const pageTitle="Historical College Basketball Ratings";
    return (
      <Page location={this.props.location.href} pageTitle={pageTitle}>
      <h1>{pageTitle}</h1>
            <p>Ratings are avaiable for all seasons going back to 2015. The ratings for past seasons were devised using the same model. It is interesting to note how the home edge was reduced during the<Button variant="text" onClick={(e)=>this.selectSeason('2021')}>covid year</Button>.
            </p> 
    <Table>
        <TableBody>
        <TableRow>
            {Seasons.map(s=><TableCell key={s['code']}>{this.yearButton(s['code'], s['name'])}</TableCell>)}
        </TableRow>
        </TableBody>
    </Table>

      <h2>{this.selectedSeasonName()}</h2>
      <RatingsGrid season={this.state.season_code} />
    </Page>
    );
  }
};
